import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';

const VideoModal = ({ video, thumb }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <div className="video-thumbnail" onClick={handleShow}>
        <img
          src={thumb}
          className="img-fluid rounded"
          style={{ cursor: 'pointer' }}
        />
        <div
          className="play-button"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70px',
            height: '50px',
            background: 'rgba(0, 0, 0, 0.7)',
            borderRadius: '5px',
            textAlign: 'center',
            lineHeight: '50px',
            color: '#fff',
            fontSize: '24px',
            cursor: 'pointer',
            boxShadow: '0 0 10px #fff',
          }}
        >
          ▶
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="review-modal"
        aria-labelledby="video-modal"
        centered
      >
        <Modal.Body style={{ padding: 0 }}>
          <CloseButton onClick={handleClose} />
          <iframe
            title="Video Player"
            width="100%"
            height="800px"
            src={video}
            frameBorder="0"
            allowFullScreen
            style={{ border: 'none' }}
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoModal;